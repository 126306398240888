import React, {useEffect, useState} from 'react';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Accordion from 'react-bootstrap/Accordion';
import slugify from 'react-slugify';
import { Rating, ThinStar } from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ReactMarkdown from "react-markdown";

const Content = ({id}) => {

    const [content, setContent] = useState([]);

    const client = createClient({
      space: 'qzvdhv6dk1nu',
      environment: 'master',
      accessToken: 'yF08NDjz2PAvDXY7SsJ57o2vkwpxT1zOjunvfbNOx_4'
    })

    const customStyles = {
      itemShapes: ThinStar,
      activeFillColor: '#FFC93A',
      inactiveFillColor: '#A5A2B2',
    };

    var htmlElement = document.querySelector("html");

    function setFaq() {
      let hasBeenCalled = false;
      htmlElement.setAttribute("itemscope", "");
      htmlElement.setAttribute("itemtype", "https://schema.org/FAQPage");
   
      return function () {
          if (!hasBeenCalled) {
              hasBeenCalled = true;
          }
      };
  }

  const callOnce = setFaq();
  
    //   function getRating(rating) {
    //   switch (rating) {
    //     case 1:
    //       return 'Poor';
    //     case 2:
    //       return 'Nothing special';
    //     case 3:
    //       return 'Average';
    //     case 4:
    //       return 'Very good';
    //     case 5:
    //       return 'Excellent';
    //     default:
    //       return 'None';
    //   }
    // }
  
    const [rating, setRating] = useState(0);
  
    useEffect (() => {
      const getContent = async () => {
        try {
          await client.getEntry(id).then((entries) => {
            setContent(entries);
            //console.log(entries);
          })
        } catch(error) {
          console.log(error);
        }
  
      }
      getContent();
  
    }, [])
  
    if (!content) {
      return "Loading...";
    }

    const renderOptions = {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node, children) => {
          // Accordion (or FAQ)
          if (node?.data?.target?.sys?.contentType?.sys?.id === "faq") {
            return (
              <>
              {node?.data?.target?.fields?.type ? 
                <div className="faq-block">
                <Accordion className="faq-wrapper bg-light" itemScope itemType="https://schema.org/FAQBody" flush>
                  
                  <Accordion.Item className="faq-single mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header className="faqtitle" itemProp="name">{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id} className="faqanswer" itemScope="" itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                        <div itemProp="text" className="text-lg">{node?.data?.target?.fields?.antwort}
                        </div>
                    </Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div>
              : 
                <div className="accordion-block">
                <Accordion flush className="bg-light">
                  
                  <Accordion.Item className="mb-2" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header>{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id}>{node?.data?.target?.fields?.antwort}</Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div> 
              }
              </>
            );
          }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
          // Casino
          if (node?.data?.target?.sys?.contentType?.sys?.id === "casino") {
            return (
              <div className="single-entry single-casino h-auto d-flex flex-wrap mb-3 border border-2 border-secondary">
                <div className="col-12 col-xxl-2 column-01">
                  <div className={node?.data?.target?.fields?.logoBackground ? "d-flex flex-wrap justify-content-between justify-content-lg-center align-items-center p-2 h-100" : "d-flex flex-wrap justify-content-between justify-content-lg-center align-items-center bg-black bg-lg-ash p-2"} 
                  style={node?.data?.target?.fields?.logoBackground ? {background: '#' + node?.data?.target?.fields?.logoBackground} : null}>
                    <div className="img-wrap d-flex col-3 col-xxl-12 justify-content-center order-2 order-xxl-1">
                      <img src={node?.data?.target?.fields?.featuredImage.fields.file.url + '?h=50'} alt={node?.data?.target?.fields?.title}></img>
                    </div>
                    <div className="d-flex col-3 col-xxl-12 mt-xxl-1 fs-9 fs-md-8 fw-bold align-items-center justify-content-center order-1 order-xxl-2">
                      <div className={node?.data?.target?.fields?.von ? 'bg-secondary py-1 px-2 text-center' : ''}>{node?.data?.target?.fields?.von ? 'von ' + node?.data?.target?.fields?.von : ''}
                      </div>
                    </div>
                    <div className="d-flex col-3 col-xxl-12 mt-xxl-1 order-3 payment-methods">
                      <div className={node?.data?.target?.fields?.featuredPaymentMethod ? node?.data?.target?.fields?.featuredPaymentMethod + ' pay-method payment-field d-flex w-100 fs-9 fs-lg-8 py-1 px-2 fw-bold text-uppercase justify-content-center' : 'pay-method payment-field d-flex w-100 fs-9 fs-lg-8 py-1 px-2 fw-bold text-uppercase justify-content-center'}>
                        <span className={node?.data?.target?.fields?.featuredPaymentMethod ? node?.data?.target?.fields?.featuredPaymentMethod + ' pay-logo' : 'pay-logo'}></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xxl-8 d-grid casino-grid d-lg-flex flex-column flex-lg-row bg-light px-2 py-3 p-lg-0 py-lg-2">
                  <div className="d-col-1 col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center bg-white">
                    <div className="fs-1 fs-lg-5 fw-bold">{node?.data?.target?.fields?.rating ? node?.data?.target?.fields?.rating + ' / 5' : ''}</div>
                    <Rating
                      style={{ maxWidth: 86 }}
                      value={node?.data?.target?.fields?.rating}
                      itemStyles={customStyles}
                      readOnly
                    />
                  </div>

                  <div className="d-col-2 col-12 col-lg-2 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                    <div className="fs-6 ps-3 ps-lg-0">Bonus</div>
                    <div className="display-3 ps-3 ps-lg-0 fs-lg-5 fw-bold text-primary">{node?.data?.target?.fields?.bonus ? node?.data?.target?.fields?.bonus + '€' : node?.data?.target?.fields?.bonus}</div>
                  </div>

                  <div className="d-col-3 col-12 col-lg-2 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                    <div className="fs-8 fs-md-6 ps-3 ps-lg-0 ">RTP</div>
                    <div className="fs-7 fs-md-5 fw-bold ps-3 ps-lg-0 ">{node?.data?.target?.fields?.rtp ? node?.data?.target?.fields?.rtp + '%' : node?.data?.target?.fields?.rtp}</div>
                  </div>

                  <div className="d-col-4 col-12 col-lg-3 d-flex flex-column justify-content-center align-items-lg-center bg-light">
                    <div className="fs-8 fs-md-6 ps-2 ps-lg-0">Auszahlung</div>
                    <div className="fs-7 fs-md-5 ps-2 ps-lg-0 fw-bold">{node?.data?.target?.fields?.auszahlungen ? node?.data?.target?.fields?.auszahlungen : '-'}</div>
                  </div>

                  <div className="d-none col-12 col-lg-2 d-lg-flex flex-column justify-content-center align-items-center bg-light">
                    <div className="fs-6">Freispiele</div>
                    <div className="fs-5 fw-bold">{node?.data?.target?.fields?.freispiele}</div>
                  </div>
                </div>

                <div className="col-12 col-xxl-2 d-flex flex-column justify-content-center align-items-center bg-light pb-3 pb-lg-0">
                  <a href={`/casinos/${node?.data?.target?.fields?.slug}`}
                  className="fs-6 fw-bold py-2 px-4 text-dark bg-secondary text-decoration-none text-center bg-hover-secondary">Testbericht</a>
                </div>

              </div>
            );
          }
          // Accordion (or FAQ)
          if (node?.data?.target?.sys?.contentType?.sys?.id === "faq") {
            callOnce();

            return (
              <>
              {node?.data?.target?.fields?.type ? 
                <div className="faq-block">
                <Accordion className="faq-wrapper bg-light" flush>
                  
                  <Accordion.Item className="faq-single mb-2" itemScope itemProp="mainEntity" itemType="https://schema.org/Question" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header className="faqtitle" itemProp="name">{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id} className="faqanswer" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                      <div itemProp="text" className="text-lg">{node?.data?.target?.fields?.antwort}</div>
                    </Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div>
              : 
                <div className="accordion-block">
                <Accordion flush className="bg-light">
                  
                  <Accordion.Item className="mb-2" eventKey={node?.data?.target?.sys?.id}>

                    <Accordion.Header>{node?.data?.target?.fields?.frage}</Accordion.Header>

                    <Accordion.Body id={node?.data?.target?.sys?.id}>{node?.data?.target?.fields?.antwort}</Accordion.Body>

                  </Accordion.Item>
                  
                </Accordion>
                </div> 
              }
              </>
            );
          }
          // Quote
          if (node?.data?.target?.sys?.contentType?.sys?.id === "quote") {
            return (
              <div className="row gx-5 flex-column flex-md-row my-5">
              <div className="col-12 ">
                {node?.data?.target?.fields?.headline ?
                <h2 id={slugify(node?.data?.target?.fields?.headline)} className="mb-5">{node?.data?.target?.fields?.headline}</h2>
                : null }
                <div className={
                  node?.data?.target?.fields?.type === 'default' ? 'quote p-4 bg-white fst-italic fs-6 lh-base' 
                  : node?.data?.target?.fields?.type === 'with_profile' ? 'quote with-profile p-4 bg-white fst-italic' 
                  : node?.data?.target?.fields?.type === 'legal_paragraph' ? 'quote legal p-4 bg-white fst-italic fs-6 lh-base' 
                  : 'quote p-4 bg-white fst-italic fs-6'}>
                {node?.data?.target?.fields?.type === 'with_profile' ?
                  <div className="d-flex align-items-center">
                    <img src={node?.data?.target?.fields?.image?.fields?.file?.url} alt={node?.data?.target?.fields?.file?.title}></img>
                    <div className="d-flex flex-column ms-3">
                      <h4 className="text-primary mb-1 fst-normal">{node?.data?.target?.fields?.name}</h4>
                      <p className="fst-normal fs-6">{node?.data?.target?.fields?.role}</p>
                    </div>
                  </div>
                : null }
                {node?.data?.target?.fields?.type === 'legal_paragraph' ?
                  node?.data?.target?.fields?.legalPMT ?
                  <div className="marked-text bg-primary text-white fs-3 d-inline-flex ps-3 pe-5 position-absolute top-0 start-0">{node?.data?.target?.fields?.legalPMT}</div>
                  : null
                : null }
                  <div className="rating-text mt-3">
                    {node?.data?.target?.fields?.text}
                  </div>
                  {node?.data?.target?.fields?.type === 'legal_paragraph' ?
                  <div className="signature d-flex flex-wrap align-items-center border border-0 border-top pt-3 mt-4 fst-normal">
                    <span className="me-1 fw-bold">{node?.data?.target?.fields?.legalSignatureBold}</span>
                    <span className="me-1">{node?.data?.target?.fields?.legalSignature}</span>
                  </div>
                  : null }
                </div>
              </div>
            </div>
            );
          }
          // Video
          if (node?.data?.target?.sys?.contentType?.sys?.id === "videoEmbed") {
            return (
              <iframe
                src={node?.data?.target?.fields?.embedUrl}
                height="100%"
                width="100%"
                frameBorder="0"
                scrolling="no"
                title={node?.data?.target?.fields?.title}
                allowFullScreen={true}
                style={{aspectRatio: "16/9"}}
                className='videoframe'
              />
            );
          }
          // Info Box
          if (node?.data?.target?.sys?.contentType?.sys?.id === "colorBox") {
            return (
              <>
              <div className="my-5">
                {node?.data?.target?.fields?.showTitle ?
                <h3 className="mb-4">{node?.data?.target?.fields?.title}</h3>
                : null}
                <div id={node?.data?.target?.fields?.type.toLowerCase()} className={'info-box bg-' + node?.data?.target?.fields?.type.toLowerCase() + ' d-flex flex-column flex-md-row p-3 p-md-4 align-items-center border-5 border-start border-' + node?.data?.target?.fields?.type.toLowerCase()}>
                  <div className="col-12 col-md-2 col-xl-1 d-flex justify-content-center align-items-center me-lg-3">
                    { node?.data?.target?.fields?.icon ?
                    <div className={'info-icon mb-3 mb-md-0 icon-' + node?.data?.target?.fields?.type.toLowerCase()}></div>
                    : null }
                  </div>
                <span>
                <ReactMarkdown>{node?.data?.target?.fields?.text}</ReactMarkdown>
                </span>
                </div>
              </div>
              </>
            );
          }
          // CTA
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentCta") {
            return (
              <>
              <div className="my-4">
                <div id={slugify(node?.data?.target?.fields?.headline)} className={'info-box border border-color-' + node?.data?.target?.fields?.borderColor + ' d-flex flex-column flex-md-row p-2 align-items-stretch border-5'}>
                { node?.data?.target?.fields?.image ?
                  <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-center align-items-center p-2" style={node?.data?.target?.fields?.logoBackground ? {background: '#' + node?.data?.target?.fields?.logoBackground} : null}>
                  <img 
                  src={node?.data?.target?.fields?.image?.fields?.file?.url + '?h=80'} 
                  alt={node?.data?.target?.fields?.file?.title}
                  className="mb-0"></img>
                  </div>
                  : null }
                  <div className="col-12 col-md-8 col-xl-9 ps-md-3">
                    <h4 className="mb-2 mt-3 mt-md-0">{node?.data?.target?.fields?.headline}</h4>
                    <span>{node?.data?.target?.fields?.text}
                    </span>
                    <div className="cta-footer d-flex flex-column flex-md-row align-items-center">
                      <div className="col-12 col-md-8 mt-2 mt-md-0">{documentToReactComponents(node?.data?.target?.fields?.footer)}</div>
                      {node?.data?.target?.fields?.buttonText ? 
                      <div className="d-inline-flex mt-2 mt-md-0 col-12 col-md-4 justify-content-center justify-content-md-end align-items-end">
                        <a className="btn btn-secondary rounded-0" href={node?.data?.target?.fields?.buttonLink ? node?.data?.target?.fields?.buttonLink : '#'}>{node?.data?.target?.fields?.buttonText}</a>
                      </div>
                      : null }
                    </div>
                  </div>
                </div>
              </div>
              </>
            );
          }
          // Quote
          if (node?.data?.target?.sys?.contentType?.sys?.id === "quote") {
            return (
              <div className="row gx-5 flex-column flex-md-row mt-5">
              <div className="col-12 ">
                <h2 id={slugify(node?.data?.target?.fields?.headline)} className="block-type-heading mb-5">{node?.data?.target?.fields?.headline}</h2>
                <div className={
                  node?.data?.target?.fields?.type === 'default' ? 'quote p-4 bg-white fst-italic fs-6 lh-base' 
                  : node?.data?.target?.fields?.type === 'with_profile' ? 'quote with-profile p-4 bg-white fst-italic' 
                  : node?.data?.target?.fields?.type === 'legal_paragraph' ? 'quote legal p-4 bg-white fst-italic fs-6 lh-base' 
                  : 'quote p-4 bg-white fst-italic fs-6'}>
                {node?.data?.target?.fields?.type === 'with_profile' ?
                  <div className="d-flex align-items-center">
                    <img src={node?.data?.target?.fields?.image?.fields?.file?.url} alt={node?.data?.target?.fields?.file?.title}></img>
                    <div className="d-flex flex-column ms-3">
                      <h4 className="text-primary mb-1 fst-normal">{node?.data?.target?.fields?.name}</h4>
                      <p className="fst-normal fs-6">{node?.data?.target?.fields?.role}</p>
                    </div>
                  </div>
                : null }
                {node?.data?.target?.fields?.type === 'legal_paragraph' ?
                  node?.data?.target?.fields?.legalPMT ?
                  <div className="marked-text bg-primary text-white fs-3 d-inline-flex ps-3 pe-5 position-absolute top-0 start-0">{node?.data?.target?.fields?.legalPMT}</div>
                  : null
                : null }
                  <div className="rating-text mt-3">
                    {node?.data?.target?.fields?.text}
                  </div>
                  {node?.data?.target?.fields?.type === 'legal_paragraph' ?
                  <div className="signature d-flex flex-wrap align-items-center border border-0 border-top pt-3 mt-4 fst-normal">
                    <span className="me-1 fw-bold">{node?.data?.target?.fields?.legalSignatureBold}</span>
                    <span className="me-1">{node?.data?.target?.fields?.legalSignature}</span>
                  </div>
                  : null }
                </div>
              </div>
            </div>
            );
          }
          //Pros and Cons
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentVorNachTeile") {
            return (
              <div className={node?.data?.target?.fields?.type ? 'vornach vorteile mb-4 col-12 col-md-6 d-inline-block align-top' : 'vornach nachteile mb-4 col-12 col-md-6 d-inline-block'}>
                  <div className="check-list">{node?.data?.target?.fields?.list?.map((vornach) => 
                    <div className="check-item d-flex align-items-start mb-2 fw-bolder" key={vornach}>
                      <div className="icon me-2"></div>
                      <span>{vornach}</span>
                    </div>)}
                  </div>
              </div>
            );
          }
          // Text Block
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentTextBlock") {
            const renderNestedOptions = {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                  // render the EMBEDDED_ASSET as you need
                  if (node.data.target.fields.file.contentType === 'video/mp4') {
                    return (
                      <iframe
                        src={node.data.target.fields.file.url}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        scrolling="no"
                        title={node.data.target.fields.title}
                        allowFullScreen={true}
                        style={{aspectRatio: "16/9"}}
                        className='videoframe'
                      />
                    );
                  };
                  return (
                    <>
                    <img
                      src={`https://${node?.data?.target?.fields?.file?.url}`}
                      height={node?.data?.target?.fields?.file?.details?.image?.height}
                      width={node?.data?.target?.fields?.file?.details?.image?.width}
                      alt={node?.data?.target?.fields?.title}
                    />
                    <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
                    </>
                  );
                }
              }
            }
            return (
              <div className="row gx-5 flex-column flex-md-row my-4">
              <div className="col-12 ">
                {node?.data?.target?.fields?.headline ? 
                  <h2 id={slugify(node?.data?.target?.fields?.headline)} className="mb-4 block-type-heading">{node?.data?.target?.fields?.headline}</h2>
                : null }
                {node?.data?.target?.fields?.rating ?
                <div className="rating-value d-flex align-items-center">
                  <Rating className="rating-stars"
                    style={{ maxWidth: 86 }}
                    value={node?.data?.target?.fields?.rating}
                    itemStyles={customStyles}
                    readOnly
                  />
                  <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.rating}</span>/5</div>
                </div>
                :null }
                <div className={node?.data?.target?.fields?.contentListStyle === 'default' ? 'rating-text mt-3' : node?.data?.target?.fields?.contentListStyle === 'check' ? 'ca-list list-check rating-text mt-3' : node?.data?.target?.fields?.contentListStyle === 'number' ? 'ca-list list-number rating-text mt-3': 'rating-text mt-3'}>
                  {documentToReactComponents(node?.data?.target?.fields?.content, renderNestedOptions)}
                </div>
              </div>
            </div>
            );
          }
          // Experten Erfahrung
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentEerfahrung") {
            return (
              <div className="flex-column flex-md-row mt-5 pt-5">
              <h2 id={slugify(node?.data?.target?.fields?.headline)} className="block-type-heading mb-4">{node?.data?.target?.fields?.headline}</h2>
                <div className="overflow-x-auto">
                  <table className="mt-0 cas-table">
                    <tbody>
                      {node?.data?.target?.fields?.spielangebot ?
                      <tr>
                        <td>Spielangebot</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.spielangebot}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.spielangebot}</span>/5</div>
                        </div></td>
                      </tr>
                      : null }  
                      {node?.data?.target?.fields?.boniUndPromotionen ?
                      <tr>
                        <td>Boni und Promotionen</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.boniUndPromotionen}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.boniUndPromotionen}</span>/5</div>
                        </div></td>
                      </tr>
                      : null } 
                      {node?.data?.target?.fields?.einUndAuszahlungen ?
                      <tr>
                        <td>Ein- und Auszahlungen</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.einUndAuszahlungen}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.einUndAuszahlungen}</span>/5</div>
                        </div></td>
                      </tr>
                      : null } 
                      {node?.data?.target?.fields?.kundenserviceUndInformationen ?
                      <tr>
                        <td>Kundenservice und Informationen</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.kundenserviceUndInformationen}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.kundenserviceUndInformationen}</span>/5</div>
                        </div></td>
                      </tr>
                      : null } 
                      {node?.data?.target?.fields?.mobileCasinoApp ?
                      <tr>
                        <td>Mobile Casino App</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.mobileCasinoApp}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.mobileCasinoApp}</span>/5</div>
                        </div></td>
                      </tr>
                      : null } 
                      {node?.data?.target?.fields?.sicherheitUndFairness ?
                      <tr>
                        <td>Sicherheit und Fairness</td>
                        <td><div className="rating-value d-flex align-items-center">
                          <Rating className="rating-stars"
                            style={{ maxWidth: 86 }}
                            value={node?.data?.target?.fields?.sicherheitUndFairness}
                            itemStyles={customStyles}
                            readOnly
                          />
                          <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.sicherheitUndFairness}</span>/5</div>
                        </div></td>
                      </tr>
                      : null } 
                    </tbody>
                  </table>
                </div>
            </div>
            );
          }
          // RTP
          if (node?.data?.target?.sys?.contentType?.sys?.id === "componentRtp") {
            return (
              <div className="row  flex-column flex-md-row mt-5 pt-5 ca-list list-number">
                <div className="col-12">
                  <h2 id={slugify(node?.data?.target?.fields?.headline)} className="block-type-heading mb-4">{node?.data?.target?.fields?.headline}</h2>
                  <div className="rtp-content">{documentToReactComponents(node?.data?.target?.fields?.content)}</div>
                  <div className="overflow-x-auto">
                    <table className="tableclass my-4 colsX2">
                      <tbody>
                        <tr>
                          <th><b>Casino Spiele</b></th>
                          <th><b>Auszahlungsquote RTP %</b></th>
                        </tr>
                        <tr>
                          <td>Spielautomaten</td>
                          <td>{node?.data?.target?.fields?.rtp} %</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mt-5 bg-light d-flex flex-column flex-md-row p-4">
                    <div className="d-flex flex-column col-12 col-md-6 justify-content-center">
                      <h5>Bewertung der Auszahlung</h5>
                      <p className="mb-3">{node?.data?.target?.fields?.text}</p>
                    </div>
                    <div className="d-flex flex-column col-12 col-md-6 align-items-center justify-content-center">
                      <div style={{ maxWidth: 320, maxHeight: 320 }}>
                        <CircularProgressbar 
                        value={node?.data?.target?.fields?.rtp} 
                        text={`${node?.data?.target?.fields?.rtp}%`} 
                        strokeWidth={7}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          textColor: "#36B37E",
                          pathColor: "#36B37E",
                          trailColor: "#EBEAEA",
                        })}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          // Zahlungsmethoden
          if (node?.data?.target?.sys?.contentType?.sys?.id === "auszahlungsmethoden") {
            return (
              <div className="methoden row gx-5 flex-column flex-md-row mt-5 ca-list list-check">
              <div className="col-12">
                <h2 id={node?.data?.target?.fields?.type ? 'ca_einzahlungen-h2' : 'ca_auszahlungen-h2'} className="block-type-heading mb-4">{node?.data?.target?.fields?.headline ? node?.data?.target?.fields?.headline : node?.data?.target?.fields?.type ? 'Einzahlungen' : 'Auszahlungen'}</h2>
                {node?.data?.target?.fields?.rating ?
                <div className="rating-value d-flex align-items-center">
                  <Rating className="rating-stars"
                    style={{ maxWidth: 86 }}
                    value={node?.data?.target?.fields?.rating}
                    itemStyles={customStyles}
                    readOnly
                  />
                  <div className="rating-number fw-bold ms-1"><span className="fs-5">{node?.data?.target?.fields?.rating}</span>/5</div>
                </div>
                : null }
                <div className="rating-text mt-3">
                  {documentToReactComponents(node?.data?.target?.fields?.content)}
                </div>
              </div>
              <div className="col-12 mt-1 overflow-x-auto">
                <table className="tableclass mb-4 payment-methods fs-9 fs-sm-7">
                  <tbody>
                    <tr>
                      <th><b>{node?.data?.target?.fields?.type ? 'Einzahlungsmethode' : 'Auszahlungsmethode'}</b></th>
                      <th><b>Mindestbetrag</b></th>
                      <th><b>{node?.data?.target?.fields?.type ? 'Einzahlungslimit' : 'Auszahlungslimit'}</b></th>
                      <th><b>Gebühren</b></th>
                    </tr>
                    {node?.data?.target?.fields?.paypal ?
                    <tr>
                      <td className="pay-method"><span className="pay-logo paypal me-2"></span> PayPal</td>
                      <td>{node?.data?.target?.fields?.paypalMLG?.[0]}</td>
                      <td>{node?.data?.target?.fields?.paypalMLG?.[1]}</td>
                      <td>{node?.data?.target?.fields?.paypalMLG?.[2]}</td>
                    </tr>
                    : null }
                    {node?.data?.target?.fields?.visa ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo visa me-2"></span> Visa</td>
                        <td>{node?.data?.target?.fields?.visaMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.visaMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.visaMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.mastercard ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo mastercard me-2"></span> Mastercard</td>
                        <td>{node?.data?.target?.fields?.mastercardMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.mastercardMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.mastercardMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.trustly ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo trustly me-2"></span> Trustly</td>
                        <td>{node?.data?.target?.fields?.trustlyMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.trustlyMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.trustlyMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.klarna ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo klarna me-2"></span> Klarna</td>
                        <td>{node?.data?.target?.fields?.klarnaMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.klarnaMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.klarnaMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.neteller ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo neteller me-2"></span> Neteller</td>
                        <td>{node?.data?.target?.fields?.netellerMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.netellerMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.netellerMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.skrill ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo skrill me-2"></span> Skrill</td>
                        <td>{node?.data?.target?.fields?.skrillMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.skrillMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.skrillMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.giropay ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo giropay me-2"></span> GiroPay</td>
                        <td>{node?.data?.target?.fields?.giropayMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.giropayMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.giropayMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.googlepay ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo googlepay me-2"></span> GooglePay</td>
                        <td>{node?.data?.target?.fields?.googlepayMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.googlepayMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.googlepayMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.amazonpay ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo amazonpay me-2"></span> AmazonPay</td>
                        <td>{node?.data?.target?.fields?.amazonpayMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.amazonpayMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.amazonpayMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.paysafe ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo paysafe me-2"></span> Paysafe</td>
                        <td>{node?.data?.target?.fields?.paysafeMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.paysafeMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.paysafeMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.bank ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo bank me-2"></span> Banküberweisung</td>
                        <td>{node?.data?.target?.fields?.bankMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.bankMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.bankMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.payz ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo payz me-2"></span> Payz</td>
                        <td>{node?.data?.target?.fields?.payzMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.payzMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.payzMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.applePay ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo applePay me-2"></span> Apple Pay</td>
                        <td>{node?.data?.target?.fields?.applePayMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.applePayMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.applePayMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.paylado ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo paylado me-2"></span> Paylado</td>
                        <td>{node?.data?.target?.fields?.payladoMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.payladoMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.payladoMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.onlineUeberweisung ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo onlineUeberweisung me-2"></span> Online Überweisung </td>
                        <td>{node?.data?.target?.fields?.onlineUeberweisungMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.onlineUeberweisungMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.onlineUeberweisungMLG?.[2]}</td>
                      </tr>
                      : null }
                      {node?.data?.target?.fields?.openBanking ?
                      <tr>
                        <td className="pay-method"><span className="pay-logo openBanking me-2"></span> Open Banking</td>
                        <td>{node?.data?.target?.fields?.openBankingMLG?.[0]}</td>
                        <td>{node?.data?.target?.fields?.openBankingMLG?.[1]}</td>
                        <td>{node?.data?.target?.fields?.openBankingMLG?.[2]}</td>
                      </tr>
                      : null }
                  </tbody>
                </table>
              </div>
            </div>
            );
          }
        },

        // If the node is a link
        [INLINES.HYPERLINK]: (node, children) => {
          // Only process youtube links
          if (node.data.uri.includes("youtube.com")) {
              // Extract videoId from the URL
              const match = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
                  node.data.uri
              )
              const videoId =
                  match && match[7].length === 11 ? match[7] : null
              return (
                  videoId && (
                  <span className="video-container d-block">
                      <iframe
                          className="video"
                          title={`https://youtube.com/embed/${videoId}`}
                          src={`https://youtube.com/embed/${videoId}`}
                          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          allowFullScreen
                      />
                  </span>
                  )
                )
          }
          else {
            return(
            <a
              href={node.data.uri}
              target={`${node.data.uri.startsWith(window.location.origin) ? '_self' : '_blank'}`}
              rel={`${node.data.uri.startsWith(window.location.origin) ? '' : 'noopener noreferrer'}`}
            >{children}</a>
            )
          }
        },
    
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
          // render the EMBEDDED_ASSET as you need
          if (node.data.target.fields.file.contentType === 'video/mp4') {
            return (
              <iframe
                src={node.data.target.fields.file.url}
                height="100%"
                width="100%"
                frameBorder="0"
                scrolling="no"
                title={node.data.target.fields.title}
                allowFullScreen={true}
                style={{aspectRatio: "16/9"}}
                className='videoframe'
              />
            );
          };
          return (
            <>
            <img
              src={`https://${node?.data?.target?.fields?.file?.url}`}
              height={node?.data?.target?.fields?.file?.details?.image?.height}
              width={node?.data?.target?.fields?.file?.details?.image?.width}
              alt={node?.data?.target?.fields?.title}
            />
            <div className="fst-italic fs-8 mb-3 text-muted">{node?.data?.target?.fields?.description}</div>
            </>
          );
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return (
            <span className="block-type-heading d-block"><h1>{children}</h1></span>
          );
         },
         [BLOCKS.HEADING_2]: (node, children) => {
          return (
            <span id={slugify(children[0]?.props?.children ? children[0]?.props?.children : children)} className="block-type-heading d-block"><h2>{children}</h2></span>
          );
         },
         [BLOCKS.HEADING_3]: (node, children) => {
          return (
            <span id={slugify(children[0]?.props?.children ? children[0]?.props?.children : children)} className="d-block"><h3>{children}</h3></span>
          );
         },
         [BLOCKS.HEADING_4]: (node, children) => {
          return (
            <span className="block-type-heading d-block"><h4>{children}</h4></span>
          );
         },
         [BLOCKS.HEADING_5]: (node, children) => {
          return (
            <span className="block-type-heading d-block"><h5>{children}</h5></span>
          );
         },
         [BLOCKS.HEADING_6]: (node, children) => {
          return (
            <span className="block-type-heading d-block"><h6>{children}</h6></span>
          );
         },

      },
    };

  return (
    <article id="article" className='main-article mt-3 mt-md-0'>
      {documentToReactComponents(content?.fields?.content, renderOptions)}
    </article>
  );
}

export default Content;