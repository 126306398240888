import React from 'react';
import Logo from './../../assets/img/CV-logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header= () => {

  return (
    <div className="headerclass container-fluid">
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-light py-0">
          <Container className="container-fluid">
            <Navbar.Brand className="navbar-brand logo d-flex align-items-center ps-2 px-sm-0" href='/'>
              <img src={Logo} alt="Casinos Vergleich Logo" width="33" height="31" ></img>
              <span className="site-title text-dark fw-bold fs-5 d-inline-flex ms-1">Casinos Vergleich</span>
            </Navbar.Brand>
            <Navbar.Toggle className='shadow-none border-0' aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Nav className="justify-content-between flex-grow-0 pe-3 d-none d-lg-flex col-6 fs-7 fw-bolder">
              <Nav.Link href="/">Spielotheken Vergleich</Nav.Link>
              <Nav.Link href="/ratgeber">Ratgeber</Nav.Link>
              <Nav.Link href="/news">News</Nav.Link>
            </Nav>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="bg-mild-green text-dark fs-4 fw-bold"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Navbar.Brand className="navbar-brand logo d-flex align-items-center" href='/'>
                  <img src={Logo} alt="Casinos Vergleich Logo" width="33" height="31" ></img>
                </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 mob-nav">
                  <Nav.Link href="/">Spielotheken Vergleich</Nav.Link>
                  <Nav.Link href="/ratgeber">Ratgeber</Nav.Link>
                  <Nav.Link href="/news">News</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default Header;